<template>
    <div>
        <Row :gutter="8" class="p-b-5">
            <i-col span="4">
              <Button type="error" size="small" long :disabled="selection.length<1" @click="handleDeleteShow()">批量删除</Button>
            </i-col>
            <i-col span="16">
              <i-input size="small" v-model="query.keyword" placeholder="输入查询关键字：站点、编号等"></i-input>
            </i-col>
            <i-col span="4">
              <Button icon="ios-search" size="small" type="primary" @click="handleSearch">搜索</Button>
            </i-col>
        </Row>

        <Row class="table-title p-t-2">
            <i-col span="2">
                <Checkbox style="margin-top: -3px" size="small" @on-change="handlCheckAllResource"></Checkbox>
            </i-col>
            <i-col span="4">所在出口</i-col>
            <i-col span="3">所属线路</i-col>
            <i-col span="4">所属站台</i-col>
            <i-col span="2">站点等级</i-col>
            <i-col span="3">数量(块)</i-col>
            <i-col span="3">位置</i-col>
            <i-col span="3">操作</i-col>
        </Row>
        <Row v-for="(resource,index) in list" :key="index" class="p-t-2" :class="computedRowClassName(resource,index)">
            <i-col span="2">
                <Checkbox style="margin-top: -3px" size="small" v-model="resource.isChecked" @on-change="handleCheckOnlyResource"></Checkbox>
            </i-col>
            <i-col span="4">{{resource.exit}}</i-col>
            <i-col span="3">{{resource.assetName}}</i-col>
            <i-col span="4">{{resource.stationName}}</i-col>
            <i-col span="2">{{resource.stationLevelName}}</i-col>
            <i-col span="3">{{resource.side}}</i-col>
            <i-col span="3">{{resource.positionName}}</i-col>
            <i-col span="3">
                <a @click="handleReplaceResource(resource)">替换</a>
                <a v-if="isManage" class="m-l-5" @click="handleChangeSchedule(resource)">档期</a>
                <a class="m-l-5 delete" v-if="!customskuId" @click="handleDeleteShow(resource)">删除</a>
            </i-col>
        </Row>

        <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" :current="query.pageNumber" show-total show-elevator @on-change="handlePageNumberChange"></Page>
        </div>

        <Modal v-model="showReplace" width="1000" title="资源替换" :footer-hide="true">
            <replaceMetro  :params="toReplace"  v-if="showReplace" @on-update="handleReplaced"></replaceMetro>
        </Modal>
        <Modal v-model="showChangeSchedule" width="400" title="资源变更档期" :footer-hide="true">
            <editOrderItemSchedule :params="params" :chooseOrderItemId="chooseOrderItemId" @on-update-order-item="changeSchduleSuccess"/>
        </Modal>
    </div>
</template>

<script>
import replaceMetro from '../common/ReplaceMetro'
import editOrderItemSchedule from '../common/EditOrderItemSchedule'

import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat'

import { getProductDetailPageByOrder } from '@/api/order/productdetail'
import { deleteOrderItem } from '@/api/order/orderitem'

export default {
  props: {
    params: {
      type: Object
    },
    customskuId: {
      type: Number,
      default: null
    }
  },
  components: {
    replaceMetro, editOrderItemSchedule
  },
  data () {
    return {
      isManage: this.$store.state.order.isManage,
      query: { pageNumber: 1, pageSize: 10, keyword: '' },
      list: [],
      total: 0,
      selection: [],
      showReplace: false,
      toReplace: {},

      showChangeSchedule: false, // 档期变更
      chooseOrderItemId: 0
    }
  },
  created () {
    this.initPageData()
    this.$emit('on-update-map', [])
  },
  methods: {
    computedRowClassName (row, index) {
      if (row.conflict) {
        return 'table-conflict-row'
      } else if (index % 2 === 0) {
        return 'table-row-1'
      } else {
        return 'table-row-2'
      }
    },
    initPageData () {
      this.query.orderId = this.params.orderId
      this.query.productId = this.params.productId
      this.query.startDate = this.params.startDate
      this.query.endDate = this.params.endDate
      this.query.priceType = this.params.priceType
      this.query.customskuId = this.customskuId || undefined
      getProductDetailPageByOrder(this.query).then(res => {
        this.total = res.totalRow
        this.list = res.list.map(x => {
          x.isChecked = false
          return x
        })
      })
    },
    handleSelectChanged (selection) {
      this.selection = selection
    },
    handleDelete (resource) {
      const data = {
        orderId: this.params.orderId,
        orderitemIds: resource ? JSON.stringify([resource.orderitemId]) : JSON.stringify(this.selection.map(s => s.orderitemId))
      }

      deleteOrderItem(data).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '删除成功' })
          this.$emit('on-update-order', this.params.categoryId)

          if (!resource) { this.selection = [] }
        }
      })
    },
    handleDeleteShow (resource) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '点击确定后资源将会从您订单里删除，请谨慎操作',
        onOk: () => {
          this.handleDelete(resource)
        }
      })
    },
    handleReplaceResource (resource) {
      this.toReplace = Object.assign({}, resource, { orderId: this.params.orderId, categoryId: this.params.categoryId })
      this.showReplace = true
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.initPageData()
    },
    formatMoney (number) {
      return toMoney(number)
    },
    formatSchedule (start, end) {
      return GetCurrentSchedule(start, end)
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    handleReplaced () {
      this.showReplace = false
      this.$emit('on-update-order', this.params.categoryId)
    },
    handlCheckAllResource (val) {
      this.selection = []
      this.list.forEach(element => {
        element.isChecked = val
        if (val) {
          this.selection.push(element)
        }
      })
    },
    handleCheckOnlyResource () {
      this.selection = []
      this.resourceList.forEach(element => {
        if (element.isChecked) {
          this.selection.push(element)
        }
      })
    },
    handleChangeSchedule (resource) {
      this.chooseOrderItemId = resource.orderitemId
      this.showChangeSchedule = true
    },
    changeSchduleSuccess () {
      this.showChangeSchedule = false
      this.$emit('on-update-order', this.params.categoryId)
    }
  },
  watch: {
    params: function () {
      this.query.pageNumber = 1
      this.initPageData()
    }
  }
}
</script>
